$primary: #b78601;

@import "~bootstrap/scss/bootstrap";

.avatar {
    max-width: 45%;
    height: auto;
    object-fit: cover;
    border-radius: 2000px;
}

.text-justify {
    text-align: justify;
}

.scroll-bar {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}
.scroll-line {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5px;
    width: 3px;
    background-color: #a9a9a9; /* Lighter shade of primary color */
}
.scroll-indicator {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #e0ad1e; /* New primary color */
    // transition: height 0.01s ease;
}
.scroll-item {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.scroll-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #a9a9a9; /* New primary color */
    transition: all 0.3s ease;
}
.scroll-circle.passed {
    background-color: #e0ad1e; /* New primary color */
}

.scroll-label {
    position: absolute;
    right: 20px;
    background-color: rgba(183, 134, 1, 0.1); /* Light primary color */
    color: #b78601; /* New primary color */
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    transition: all 0.3s ease;
    white-space: nowrap;
}
.scroll-item:hover .scroll-circle {
    background-color: #8c6701; /* Darker shade of primary color */
}
.scroll-item:hover .scroll-label {
    opacity: 1;
    right: 25px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .scroll-bar {
        right: 10px;
    }
}

@media (min-width: 992px) {
    .scroll-bar {
        right: 20px;
    }
}
